<script>
import Layout from '../../layouts/main';
import PageHeader from '@/components/PageHeader/main.vue';
import appConfig from '@/app.config';
import { dataMaster } from '@/state/helpers';
import { checkPermission } from '@/state/helpers';
import Footer from '@/components/Footer/main.vue';
/**
 * Advanced-form component
 */
export default {
    page: {
        title: 'その他管理一覧',
        meta: [{ name: 'description', content: appConfig.description }]
    },
    components: {
        Layout,
        PageHeader,
        Footer
    },
    data() {
        return {
            title: 'その他管理一覧',
            items: [
                {
                    text: 'ダッシュボード',
                    href: '/'
                },
                {
                    text: 'その他管理一覧',
                    active: true
                }
            ],
            data: dataMaster
        };
    },
    mounted() {
        if (!this.checkPermiss('master.register')) {
            this.$router.push({ path: '/' }).then(() => {
                this.$bvToast.toast(`warning`, {
                    title: `アクセス権限がありません。`,
                    variant: 'warning',
                    toaster: 'b-toaster-top-center',
                    solid: true
                });
            });
        }
    },
    methods: {
        goToEdit(path, key) {
            this.$router.push({ path: path, query: { key: key } });
        },
        checkPermiss(listRole) {
            return checkPermission(listRole);
        }
    }
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />

        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <div class="table-responsive">
                        <table class="table mb-0 table-bordered">
                            <thead>
                                <tr>
                                    <th>No</th>
                                    <th>カテゴリ</th>
                                    <th>マスター名</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <template v-for="(item, k) in data">
                                    <template>
                                        <tr :key="item.key">
                                            <th scope="row" :rowspan="item.rowspan">{{ k + 1 }}</th>
                                            <td :rowspan="item.rowspan">{{ item.name }}</td>
                                            <td v-if="item.childMain !== ''">{{ item.childMain }}</td>
                                            <td v-if="item.childMain !== ''">
                                                <a href="javascript:void(0)" @click="goToEdit('/master/other/form', item.key + '.' + item.keyMain)"
                                                    ><i class="dripicons-document-edit"></i> 詳細</a
                                                >
                                            </td>
                                        </tr>
                                        <tr v-for="(child, cI) in item.child" :key="cI">
                                            <td v-if="child.name !== ''">{{ child.name }}</td>
                                            <td v-if="child.name !== ''">
                                                <a href="javascript:void(0)" @click="goToEdit('/master/other/form', item.key + '.' + child.key)"
                                                    ><i class="dripicons-document-edit"></i> 詳細</a
                                                >
                                            </td>
                                        </tr>
                                    </template>
                                </template>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </Layout>
</template>

<style lang="scss" scoped>
td,
th {
    vertical-align: middle;
}
</style>
